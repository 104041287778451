<template>
    <div class="sup_content order-list">
        <div class="sup_page_heading">
            <h2>Free Website Order Management</h2>
        </div>
        <div class="sup_inner_content">
            <DataTable class="p-datatable order-data-table" :paginator="true" :value="allFreeInvoiceData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allFreeInvoiceData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading orders data. Please wait.
                </template>                
                <template #empty>
                    No order found...
                </template>
                <Column field="chargedAmount" header="Amount" :sortable="true">
                    <template #body="slotProps">
                        <strong>{{'$' +slotProps.data.chargedAmount}}</strong>
                    </template>
                </Column>

                <Column field="orderStatus" header="Status" :sortable="true" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['order_status ',slotProps.data.orderStatus === 'failed' ? 'order_status_danger' : 'order_status_success']"><i :class="['pi ', slotProps.data.orderStatus === 'failed' ? 'pi-times' : 'pi-check']"></i>{{capitalizeCase(slotProps.data.orderStatus)}}</span>
                    </template>
                </Column>

                <Column field="orderType" header="Invoice Type" :sortable="true"></Column>

                <Column field="website" header="Website" sortable filterField="Website" sortField="Website.siteName">
                    <template #body="slotProps">
                        <span class="sup_site_info">{{slotProps.data.Website.siteName}}</span> <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank"><i class="pi pi-external-link"></i></a>
                    </template>
                </Column>

                <Column field="state" header="Customer" sortable filterField="User" sortField="User.email">
                    <template #body="slotProps">
                    {{slotProps.data.User.email}}
                    </template>
                </Column>

                <Column field="chargedDate" header="Invoice Date" :sortable="true">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.chargedDate)}}
                    </template>
                </Column>
            </DataTable>
            <!--- Show Order Table End --->
        </div>
    </div>
</template>

<script>
import { freeWebsiteInvoices } from '../../../../config/appUrls';
import userLogout from '../../../../helper/userLogout/userLogout';
import { apiKey } from "../../../../config/constant";
import {usFormatDate, capitalizeCase} from "../../../../helper/utility/utility";
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';

export default {
    name: 'OrderManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //Invoice initial data state
        const allFreeInvoiceData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            search: null,
        });

        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            fetchAllFreeInvoice()
        });

        /**
         * Fetching all Invoice list from DB
         * intially fetching active Invoice but this fucntion user can get deactive Invoice and filter Invoice by date and name
         * @param {load} from where function calling
         */
        const fetchAllFreeInvoice = async() =>{
            //API call start
            try{
                allFreeInvoiceData.loading = !allFreeInvoiceData.loading;
                const response = await axios.get( freeWebsiteInvoices, {
                    params: {},
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allFreeInvoiceData.data = response.data.data.invoices;
                allFreeInvoiceData.total = response.data.data.total;
                allFreeInvoiceData.loading = !allFreeInvoiceData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Returing variables to template
        return{
            allFreeInvoiceData,
            usFormatDate,
            capitalizeCase
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/order/OrderList";
</style>